import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Button, Card, CardTitle, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import * as yup from 'yup';
import AuthHeader from './AuthHeader';

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (
const isSonic = window.location.href.includes('sonicleads');
class CustomSignIn extends SignIn {
  public constructor(props: any) {
    super(props);

    this._validAuthStates = ['signIn'];
  }

  public showComponent(): JSX.Element {
    // If we are on sign in screen then potentially we are changing users
    window.localStorage.removeItem('admin-network-id');
    return (
      <AuthHeader cardTitle="Sign in to Your Account">
        <Formik
          onSubmit={async () => {
            await this.signIn(null);
          }}
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={yup.object().shape({
            username: yup.string().required('Please enter an email.'),
            password: yup.string().required('Please enter a Password.'),
          })}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <BlockUI blocking={isSubmitting}>
                <Field
                  id="username"
                  name="username"
                  type="email"
                  onBlur={(event) => {
                    const {
                      target: { value },
                    } = event;
                    let val = value.toLowerCase();

                    if (isSonic) {
                      val = event.target.value.replace('@', '+1@');
                    }
                    this.inputs.username = val;
                  }}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    let val = value.toLowerCase();

                    if (isSonic) {
                      val = event.target.value.replace('@', '+1@');
                    }
                    this.inputs.username = val;
                  }}
                  placeholder="Email"
                  label="Email"
                />
                <Field
                  name="password"
                  type="password"
                  onChange={this.handleInputChange}
                  placeholder="Password"
                  label="Password"
                />
              </BlockUI>
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                color="primary"
                size="lg"
                type="submit"
                block
              >
                Sign In
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <div className="d-flex flex-column pt-2">
          <span>
            Forgot your password?{' '}
            <Button
              color="link"
              onClick={() => this.changeState('forgotPassword')}
            >
              Reset Password
            </Button>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomSignIn;
